import { Typography, Box, Button, IconButton} from '@mui/material'
import React from 'react'
import '../Components/LandingPage.css'
import Logo from '../assets/images/AIMXCEL.svg'
import Dashboard from '../assets/images/Dashboard.jpg'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import PieChartIcon from '@mui/icons-material/PieChart';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import LoginIcon from '@mui/icons-material/Login';
import GradingIcon from '@mui/icons-material/Grading';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import GroupIcon from '@mui/icons-material/Group';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import BusinessIcon from '@mui/icons-material/Business';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Logo1 from '../assets/images/logo1.png';
import Logo2 from '../assets/images/logo2.jpeg';
import Logo3 from '../assets/images/logo3.png';
import Pic1 from '../assets/images/pic1.jpg'
import Pic2 from '../assets/images/pic2.jpg'
import Pic3 from '../assets/images/pic3.png'
import Pic4 from '../assets/images/pic4.jpg'
import Modal from '@mui/material/Modal';

const LandingPage = () => {
  const [scriptLoaded, setScriptLoaded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openScriptInNewPage = () => {
    window.open('https://form.jotform.com/240645188170457', '_blank');
  };

  const loadScript = () => {
    if (!scriptLoaded) {
      const script = document.createElement('script');
      script.src = 'https://form.jotform.com/jsform/240645188170457';
      script.async = true;
      script.onload = () => {
        setScriptLoaded(!scriptLoaded);
        // Do something after script is loaded if needed <script type="text/javascript" src="https://form.jotform.com/jsform/240645188170457"></script>
      };
      document.body.appendChild(script);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <Box className='parent-body'>
        {/* Header - Step 1 */}
        <Box className='header'>
          
          <img src={Logo} className='logo' alt='' />
          <Box className='header-button'>
             <a href='https://aimxcel.com/bookdemo.html' target='_blank'>
            {/* <a class="btn lightbox-240645188170457"
            style={{
            marginTop: '16px',
            textTransform: 'uppercase',
            fontSize: '14px',
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'inline-block',
            padding: '10px',
            fontFamily: 'inherit',
            textShadow: 'none',
            userSelect: 'none',
            transition: 'all,.1s,ease-in',
            backgroundColor: '#FFA500',
            border: '1px solid #FFA500',
            color: '#FFFFFF'
            }}
            > */}
            <Button variant='contained'>
              Book a Demo
              </Button>
              </a>
          </Box>
        </Box>

        {/* Body - Step 2 and 3*/}
        <Box className='body-step2'>
          <Box className='step2-banner'>
            <Box className='font-extra-bold'>
              <Typography>
                Enterprise Grade SaaS
                Platform that gives visibility
                to your physical assets
                (Facilities, Equipment, Fleet, etc.)
              </Typography>
             
            </Box>
            <Box className='font-regular'>
              <Typography>
                No more pen and paper, spreadsheets, and inefficient software. A secure, reliable framework
                that can be configured to business needs reducing downtime, risks, and costs.
              </Typography>
            </Box>
          </Box>
          <Box>
            <img src={Dashboard} className='dashboard' alt='' />
          </Box>
          <Box className='body-step3'>
            <Box className='font-medium'>
              <Typography>
                The Company's software helps to capture, organize, track with a
                single point of view for organizations to consolidate physical asset operations,
                enhance asset lifecycles, optimize performance, and meet required regulations/compliances.
              </Typography>
            </Box>
            <Box className='border-content'>
              <Box className='card'>
                <Box className='card-thumb'>
                  <Typography>50%</Typography>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography>Saved on maintenance costs</Typography>
                </Box>
              </Box>
              <Box className='card'>
                <Box className='card-thumb'>
                  <Typography>43%</Typography>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography >Lesser unplanned outages</Typography>
                </Box>
              </Box>
              <Box className='card'>
                <Box className='card-thumb'>
                  <Typography>10X</Typography>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography>Reduced time spent on reports</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Body - Step 4 */}
        <Box className='body-step4'>
          <Box className='font-medium'>
            <Typography>Meet the Pro Active Maintenance Platform</Typography>
          </Box>

          <Box className='step4-cards'>
            <Box className='icon-content'>

              <Box className='card-step4'>
                <Box className='icon-thumb'>
                  <Box>
                    <IconButton className='icon-color'>
                      <BuildOutlinedIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography>ASSET <br />MANAGEMENT</Typography>
                </Box>
              </Box>

              <Box className='card-step4'>
                <Box className='icon-thumb'>
                  <Box>
                    <IconButton className='icon-color'>
                      <AssuredWorkloadIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography>FACILITIES MANAGEMENT</Typography>
                </Box>
              </Box>

              <Box className='card-step4'>
                <Box className='icon-thumb'>
                  <Box>
                    <IconButton className='icon-color'>
                      <GradingIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography>WORK ORDER MANAGEMENT</Typography>
                </Box>
              </Box>

            </Box>

            <Box className='icon-content'>

              <Box className='card-step4'>
                <Box className='icon-thumb'>
                  <Box>
                    <IconButton className='icon-color'>
                      <PieChartIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography>MATERIAL MANAGEMENT</Typography>
                </Box>
              </Box>

              <Box className='card-step4'>
                <Box className='icon-thumb'>
                  <Box>
                    <IconButton className='icon-color'>
                      <DomainVerificationIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography>PREDICTIVE MAINTENANCE</Typography>
                </Box>
              </Box>

              <Box className='card-step4'>
                <Box className='icon-thumb'>
                  <Box>
                    <IconButton className='icon-color'>
                      <CloudDoneIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography>CENTRALIZED WORKFLOW</Typography>
                </Box>
              </Box>

            </Box>

            <Box className='icon-content'>

              <Box className='card-step4'>
                <Box className='icon-thumb'>
                  <Box>
                    <IconButton className='icon-color'>
                      <AssessmentIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography>ADHOC <br />REPORTING</Typography>
                </Box>
              </Box>

              <Box className='card-step4'>
                <Box className='icon-thumb'>
                  <Box>
                    <IconButton className='icon-color'>
                      <LoginIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography>IDENTTITY ACCESS MANAGEMENT</Typography>
                </Box>
              </Box>

              <Box className='card-step4'>
                <Box className='icon-thumb'>
                  <Box>
                    <IconButton className='icon-color'>
                      <LaptopChromebookIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Box className='font-regular-cards'>
                  <Typography>VISUAL <br />DICTIONARY</Typography>
                </Box>
              </Box>

            </Box>
          </Box>
        </Box>

        {/* Body - Step 5 */}
        <Box className='step5'>
          <Box className='step5-grid'>
            <Box >
              <img src={Pic1} alt='' className='dashboard-step5' />
            </Box>
            <Box className='step5-data'>
              <Box className='font-medium'>
                <Typography>All-in-one platform</Typography>
              </Box>
              <Box className='font-regular'>
                <Typography sx={{ textAlign: 'justify' }}>Easy-to-use to manage facilities and equipment from anywhere. Gain full visibility
                  into your operation, keep your team accountable and no more data inconsistencies.</Typography>
              </Box>
            </Box>
          </Box>

          <Box className='step5-grid'>
            <Box className='step5-data'>
              <Box className='font-medium'>
                <Typography>Improve everyday operations</Typography>
              </Box>
              <Box className='font-regular'>
                <Typography sx={{ textAlign: 'justify' }}>Track everything about your equipment, facilities, maintenance
                  processes and never let preventive maintenance, inspections or repairs slip through the cracks. </Typography>
              </Box>
            </Box>
            <Box >
              <img src={Pic2} alt='' className='dashboard-step5' />
            </Box>
          </Box>

          <Box className='step5-grid'>
            <Box >
              <img src={Pic4} alt='' className='dashboard-step5' />
            </Box>
            <Box className='step5-data'>
              <Box className='font-medium'>
                <Typography>Manage from anywhere</Typography>
              </Box>
              <Box className='font-regular'>
                <Typography sx={{ textAlign: 'justify' }}>Handle any task anytime, anywhere with intuitive web and smartphone
                  apps designed to work offline mode.</Typography>
              </Box>
            </Box>
          </Box>

          <Box className='step5-grid'>
            <Box className='step5-data'>
              <Box className='font-medium'>
                <Typography>Digitize Processes</Typography>
              </Box>
              <Box className='font-regular'>
                <Typography sx={{ textAlign: 'justify' }}>Automate your maintenance process from end to end through powerful
                  operational workflows and data integrations. No more manual data entry.</Typography>
              </Box>
            </Box>
            <Box >
              <img src={Pic3} alt='' className='dashboard-step5' />
            </Box>
          </Box>
        </Box>

        {/* Body - Step 6 */}
        <Box className='step6-align'>
          <Box className='card-step6'>
            <Box className='step6-icon-color'>
              <GroupIcon fontSize='large' />
            </Box>
            <Box className='card-content-step6'>
              <Box className='font-medium'>
                <Typography>2500+</Typography>
              </Box>
              <Box className='font-regular'>
                <Typography>Users</Typography>
              </Box>
            </Box>
          </Box>

          <Box className='card-step6'>
            <Box className='step6-icon-color'>
              <PlagiarismIcon fontSize='large' />
            </Box>
            <Box className='card-content-step6'>
              <Box className='font-medium'>
                <Typography>100,000+</Typography>
              </Box>
              <Box className='font-regular'>
                <Typography>Assets</Typography>
              </Box>
            </Box>
          </Box>

          <Box className='card-step6'>
            <Box className='step6-icon-color'>
              <BusinessIcon fontSize='large' />
            </Box>
            <Box className='card-content-step6'>
              <Box className='font-medium'>
                <Typography>2000+</Typography>
              </Box>
              <Box className='font-regular'>
                <Typography>Facilities</Typography>
              </Box>
            </Box>
          </Box>

          <Box className='card-step6'>
            <Box className='step6-icon-color'>
              <ListAltIcon fontSize='large' />
            </Box>
            <Box className='card-content-step6'>
              <Box className='font-medium'>
                <Typography>60,000+</Typography>
              </Box>
              <Box className='font-regular'>
                <Typography>Work Orders</Typography>
              </Box>
            </Box>
          </Box>

        </Box>

        {/* Body - Step 7  */}
        <Box className='step6-grid'>
          {/*           <Box >
            <img src={Pricing} alt='' className='pricing-image' />
          </Box>
 */}
          <Box className='step6-email'>
            <Box className='font-medium'>
              <Typography>Interested to know more?</Typography>
            </Box>
            {/* <Box className='font-regular'>
              <Typography textAlign='justify'>Would you be interested in Trail cost estimate of your buisiness?
                Leave your email below and we will send you a link to the Trail pricing calulator.
              </Typography>
            </Box> */}
            <Box className='step6-email-align'>
              <a href='https://aimxcel.com/bookdemo.html' target='_blank'>
                <Button variant='contained' >Book a Demo</Button>
              </a>
             
            </Box>

          </Box>
        </Box>

        {/* Body - Step 8 */}
        <Box className='step8'>
          <Box className='step8-logos'>
            <img src={Logo1} alt='' className='step8-logo' />
            <img src={Logo2} alt='' className='step8-logo' />
            <img src={Logo3} alt='' className='step8-logo' />
          </Box>
          <Box className='step8-address'>
            <Typography>© 2024 AIMXCEL INC</Typography>
            <Typography>sales@aimxcel.com</Typography>
            <Typography>104 Woodmont Blvd, Ste 205, Nashville TN 37205</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default LandingPage
